import { css, html, LitElement } from "lit";


/**
 * Stepper class
 *
 */
window.customElements.define('stepper-component', class StepperForm extends LitElement {

    constructor () {
        super();
        this.skip = [];
        this.step = 0;
        this.steps = 0;
    }

    static get properties () {

        return {
            steps: { type: Number },
            step: { type: Number },
            skip: { type: Array },
            hide: true
        }

    }

    render () {

        const stepActive = html`
            <div class="step step-active"></div>
        `;

        const step = html`
            <div class="step"></div>
        `;

        return html`
            <div class="${this.hide ? 'steps-hide' : 'steps-holder'}">
                ${[...new Array(this.steps).entries()].map(( i, k ) => html`${k < this.step ? stepActive : step}`)}
            </div>
        `;

    }

    static get styles () {

        return css`

          :host {
            position: fixed;
            width: 100%;
            z-index: 9999;
          }

          .steps-hide {
            height: 0px;
            display: none;
          }

          .steps-holder {
            display: flex;
            flex-direction: row;
          }

          .step {
            margin-right: 0.2em;
            background-color: lightgrey;
            height: 0.5em;
            width: 100%;
          }

          .step:last-child {
            margin-right: 0em;;
          }

          .step-active {
            background-color: #15c15d;
          }
        `;
    }

});
