import './stepper-component'

export default async function Stepper ( configuration ) {

    window.scrollTo(0, 0);

    return new Promise(( resolve, err ) => {

        const { skip, colorActive, color } = configuration;

        const stepper = document.querySelector('stepper-component') || document.createElement('stepper-component');

        window.addEventListener("route-changed", ( data ) => {

            const { views, componentName } = data.detail;

            stepper.steps = views - skip.length - 1;
            stepper.hide = stepper.skip.includes(componentName);
            stepper.step = stepper.hide ? stepper.step : stepper.step + 1;


        }, { passive: true });

        stepper.skip = skip;
        stepper.colorActive = colorActive;
        stepper.color = color;

        document.body.insertBefore(stepper, document.body.firstChild);

        resolve();
    });
}
