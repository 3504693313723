import Components from './Components'

/**
 * Simple Router for FMA WeDAT
 *
 * @type {{components: [{"welcome-page": ((function(): Promise<?>)|*)},{"assign-partner": ((function(): Promise<?>)|*)},{"capture-images": ((function(): Promise<?>)|*)},{"damage-selector": ((function(): Promise<?>)|*)},{"address-form": ((function(): Promise<unknown>)|*)},null,null,null,null], currentRoute: null, go: routes.go, views: *[]}}
 */
const routes = {
    currentRoute: 0,
    views: [],
    routeChangeEvent: function (data) {
        document.dispatchEvent(new CustomEvent("route-changed", { detail: data, bubbles: true, composed: true }));
    },
    go() {

        if (this.currentRoute >= this.views.length)
            return;

        const { component } = this.views[this.currentRoute];
        const fn = Components.get(component);

        this.routeChangeEvent({
            currentRoute: this.currentRoute,
            views: this.views.length,
            componentName: component
        });

        fn(this.views[this.currentRoute]).then(() => {
            this.currentRoute++;
            routes.go()
        });


    }
};

export default routes;
