import { elem }          from "../../config";
// import ico from  '../../../public/assets/delay-icon.svg'
const errorPage = ( errorType ) => {

   const errorMessage = errorType

    elem.innerHTML = `
        <div class="error-container">
<!--           <div class="error-ico">😔</div>-->
            <div>
                <div class="error-ico">
                   <img src="/assets/delay-icon.svg" alt="Kiwi standing on oval" height="100%">
                </div>
            </div>
           <div class="message-body">
                
                <span class="error-subtitle">
                       <div class="error-subtitle-box">
                            ${errorMessage}
                        </div>
                </span>
                <!-- <span class="error-code">
                          code:${errorType}<br>
                          U:${window.location.origin}<br>
                          T:${window.location.pathname} 
               </span> -->     
                
           </div>
           </div>
            
        </div>
        <style>
           
           .error-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                
                height: 100%;
           }
           
           .message-body {
                display: flex;
                flex-direction: column;
                flex-shrink: 0;
                justify-content: center;
                justify-items: center;
           }
           
           .error-title {
                font-size: 1.5rem;;
                width: 100%;
                text-align: center;
                padding: 0.8em;
           }
           
           .error-subtitle {
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                              
           }
           
           .error-subtitle-box {
                min-width: 5rem;
                max-width: 80%;
           }
                      
           .error-code {
                margin-top: 3em;
                display: block;
                padding-top: 1em;
                font-family: "Courier New";
                font-size: 0.6em;
                width: 100%;
                overflow-wrap: anywhere;
           }
           
           .error-ico {
                display: block;
                text-align: center;
                font-size: 1rem;
                height: 1rem;
                height: 8em;;
               
           }
           
           .error-ico > img {
                height: 5em;
           }  
           
        </style>
    `;

};

export default errorPage;
