import {elem, getConfigForComponent, taskId} from "../../config";
import "./AddressFormComponent";
import localforage from "localforage";

const componentName = "address-form";
const eventAddress = "addr-resolve";

const AddressForm = function (configuration) {
    window.scrollTo(0, 0);

    return new Promise((resolve, err) => {
        localforage.getItem(taskId).then((result) => {

            /* This is model from ROOT node */
            let newResult = result;

            const {model} = result;
            const addr = {
                firstName: model?.firstName,
                lastName: model?.lastName,
                licenceplate: model?.licenceplate,
                street: model?.street,
                city: model?.city,
                zip: model?.zip,
            };

            /* This is component model */
            const {
                translates, model: {logo}, component
            } = configuration

            /* Listen for event from component */
            window.addEventListener(eventAddress, (data) => {
                const payload = data.detail;
                /* Defensive copy */
                newResult.model = {
                    ...result.model, ...payload.address, coordinates: payload?.coordinates,
                };

                /* Save data */
                localforage.setItem(taskId, newResult).then((res) => {
                    window.removeEventListener(eventAddress, resolve);
                    resolve();
                });
            }, {passive: true});

            let addressForm = document.createElement(component);

            addressForm.translates = translates;
            addressForm.coordinates = model?.coordinates;
            addressForm.firstName = model?.firstName;
            addressForm.lastName = model?.lastName;
            addressForm.zip = addr?.zip;
            addressForm.street = addr?.street;
            addressForm.city = addr?.city;
            addressForm.logo = logo;

            elem.innerHTML = "";

            elem.appendChild(addressForm);
        });
    });
};

export default AddressForm;
