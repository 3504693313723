import WelcomePage from "../views/welcome-page";
import Form from "../views/form-page";
import AssignPartner from "../views/assign-partner/AssignPartner";
import CaptureImages from "../views/capture-image/CaptureImages";
import DamageSelector from "../views/damage-selector/DamageSelectror";
import AddressForm from "../component/address-form";
import SendView from "../views/send/SendView";
import FinishView from "../views/finish/FinishView";
import Stepper    from "../component/stepper-component/Stepper";


const Components = new Map();

Components.set("welcome-page", WelcomePage);
Components.set("form-page", Form);
Components.set("assign-partner", AssignPartner);
Components.set("capture-images", CaptureImages);
Components.set("damage-selector", DamageSelector);
Components.set("address-form", AddressForm);
Components.set("send-view", SendView);
Components.set("send-component", SendView);
Components.set("finish-view", FinishView);
Components.set("finish-component", FinishView);
Components.set("stepper-component", Stepper);

export default Components;
