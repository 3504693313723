import Dialog    from "../component/dialog/Dialog"
import { guuid } from "./guuid";


const detectAdd = metaAdd => {

    const id = guuid();

    return new Promise(function ( res, rej ) {
        let script = document.createElement("script");

        script.onload = function () {
            res(false);
        };

        script.onerror = function (r) {
            res(true);
        };

        script.id = id;
        script.src = metaAdd;

        document.body.appendChild(script);
    });

}

const checkIfAdBlockEnabled = ( config ) => {

    const ad1 = "/yzfdmoan.js";
    const ad2 = "/utep_ad.js";

    Promise.all([detectAdd(ad1), detectAdd(ad2)]).then(res=>{
        if(res[0] === true || res[1] === true)
            Dialog(config);
    });


}


export default checkIfAdBlockEnabled;

