import {html, css, LitElement} from "lit";
import {
    checkGeoPermissions,
    getCurrentGeolocation,
} from "../../lib/GeoPermissions";

export class AddressFormComponent extends LitElement {
    static styles = css`
		.hero {
			background-color: #465064;
		}
	`;

    createRenderRoot() {
        return this;
    }

    static properties = {
        formValid: {type: Boolean},
        geoPermissions: {type: Boolean},
        coordinates: {type: String},
        street: {type: String},
        city: {type: String},
        zip: {type: String},
        firstName: {type: String},
        lastName: {type: String},
        logo: {type: String},
        translates: {
            converter: (k) => JSON.parse(k),
        },
    };

    constructor() {
        super();
        this.logo = "";
        this.coordinates = "";
        this.geoPermissions = this._checkPermissions();
        this.formValid = false;

        this.translates = {
            introTextOne: "Please fill the address",
            introTextTwo: "or click to get coordinates",
            streetPlaceholder: "Street and home number",
            cityPlaceholder: "City",
            zipPlaceholder: "Zip",
            firstNamePlaceholder: "First name",
            lastNamePlaceholder: "Last name",
            coordinatePlaceholder: "Click button to get coordinate",
            warning:
                " Warning: The coordinates have priority over the address and are not related to a specific address",
            btnNext: "Next",
        };

        this._validateForm();
    }

    _getCoordinates() {
        getCurrentGeolocation().then((result) => {
            this.coordinates = result;
        });
    }

    _clearCoordinates() {
        this.coordinates = "";
    }

    _validateFormFields() {
        return (
            this.city && this.zip && this.street // this.firstName && this.lastName
        );
    }

    _validateGeoPosition() {
        return (
            this.coordinates &&
            this.coordinates.latitude &&
            this.coordinates.longitude
        );
    }

    _validateForm() {
        this.formValid = !!(this._validateFormFields() && this._validateGeoPosition());
    }

    _prepareData() {
        const _data = {
            coordinates: this.coordinates,
            address: {
                firstName: this.firstName,
                lastName: this.lastName,
                zip: this.zip,
                city: this.city,
                street: this.street,
            },
        };

        return _data;
    }

    firstUpdated(cha) {
    }

    updated(changedProperties) {
        this._validateForm();
    }

    _checkPermissions() {
        checkGeoPermissions().then((result) => {
            this.geoPermissions = result.state === "granted" || result.state === "prompt";
        });
    }

    resolve() {
        const data = this._prepareData();

        this.dispatchEvent(
            new CustomEvent("addr-resolve", {
                detail: data,
                bubbles: true,
                composed: true,
            })
        );
    }

    render() {
        return html`
            <section class="hero is-fullheight">
                <div class="hero-body">
                    <div class="container">
                        <div class="columns is-5-tablet is-4-desktop is-3-widescreen">
                            <div class="column">
                                <div class="box">
                                    <div
                                            class="field has-text-centered"
                                            ?hidden=${
                                                    this.logo === undefined ||
                                                    this.logo === ""
                                            }
                                    >
                                        <img alt="logo"  src="${
                                                this.logo
                                        }" width="47" alt="logo"/>
                                    </div>

                                    <div class="content">
                                        <p class="has-text-grey">${
                                                this.translates.introTextOne
                                        }</p>
                                    </div>
                                        <!-- 
									<div class="field is-horizontal">
										<div class="field-body">
											<div class="field is-expanded">
												<div class="field">
													<p class="control is-expanded">
														<input
															name="firstName"
															class="input"
															type="text"
															placeholder="${this.translates.firstNamePlaceholder}"
															.value="${this.firstName || ""}"
															@input=${(e) => (this.firstName = e.target.value)}
														/>
													</p>
												</div>
											</div>
										</div>
									</div>

									<div class="field is-horizontal">
										<div class="field-body">
											<div class="field is-expanded">
												<div class="field">
													<p class="control is-expanded">
														<input
															name="lastName"
															class="input"
															type="text"
															.placeholder=${this.translates.lastNamePlaceholder}
															.value="${this.lastName || ""}"
															@input=${(e) => (this.lastName = e.target.value)}
														/>
													</p>
												</div>
											</div>
										</div>
									</div> -->

                                    <div class="field is-horizontal">
                                        <div class="field-body">
                                            <div class="field is-expanded">
                                                <div class="field">
                                                    <p class="control is-expanded">
                                                        <input
                                                                name="street"
                                                                class="input"
                                                                type="text"
                                                                placeholder="${this.translates.streetPlaceholder}"
                                                                .value="${this.street || ""}"
                                                                @input=${(e) => (this.street = e.target.value)}
                                                        />
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field is-horizontal">
                                        <div class="field-body">
                                            <div class="field">
                                                <div class="control is-expanded">
                                                    <input
                                                            name="city"
                                                            class="input"
                                                            type="text"
                                                            placeholder="${this.translates.cityPlaceholder}"
                                                            .value="${this.city || ""}"
                                                            @input=${(e) => (this.city = e.target.value)}
                                                    />
                                                </div>
                                            </div>

                                            <div class="field">
                                                <div class="control">
                                                    <input
                                                            class="input"
                                                            name="zip"
                                                            type="text"
                                                            placeholder="${this.translates.zipPlaceholder}"
                                                            .value="${this.zip || ""}"
                                                            @input=${(e) => (this.zip = e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="content">
                                        <p class="has-text-grey">
                                            ${this.translates.introTextTwo}
                                        </p>
                                    </div>
                                    <div class="field has-addons">
                                        <div class="control">
                                            
                                            <button
                                                    class="button"

                                                    @click="${() => this._getCoordinates()}"
                                            >
												<span class="icon is-small">
													<i class="fa-solid fa-location-dot"></i></i>
												</span>
                                            </button>
                                        </div>
                                        <div class="control is-expanded">
                                            <input
                                                    .value="${this.coordinates?.latitude} ${this.coordinates?.longitude}"
                                                    class="input"
                                                    type="text"
                                                    placeholder="${this.translates.coordinatePlaceholder}"
                                                    disabled
                                            />
                                        </div>
                                        <p class="control">
                                            <button
                                                    class="button is-danger"
                                                    ?disabled=${!this.geoPermissions}
                                                    @click="${() => this._clearCoordinates()}"
                                            >
												<span class="icon is-small">
													<i class="fas fa-times"></i>
												</span>
                                            </button>
                                        </p>
                                    </div>

                                    <div class="content">
                                        <p class="has-text-danger">
                                            ${
                                                    !this._validateGeoPosition()
                                                            ? this.translates.warning
                                                            : ""
                                            }&nbsp;
                                        </p>
                                    </div>

                                    <div class="block">
                                        <div class="field is-grouped is-grouped-right">
                                            <p class="control">
                                               
                                                <button
                                                        @click=${() => this.resolve()}
                                                        class="button is-info"
                                                        ?disabled=${!this.formValid}
                                                >
                                                    ${this.translates.btnNext}
                                                </button>
                                                
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        `;
    }
}

window.customElements.define("address-form", AddressFormComponent);
