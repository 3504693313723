/*jshint esversion: 11 */

import "./style.css";
import errorPage from "./component/error-page/error-page";
import { apiUrl, localforage, taskId } from "./config";
import routes from "./router/router";
import checkIfAdBlockEnabled from "./lib/AdDetect";
import i18n from "./i18n";

/* Get configuration */

if (apiUrl) {
    fetch(apiUrl, {
        method: 'GET',
        headers: {
            "Accept": "application/json"
        }
    })
        .then((response) => {
            if (response.status === 200) return response.json();

            return response;
        })

        /* Process configuration */
        .then((response) => {

            if (Object.keys(response).length > 0) {

                const { config, model, wedat } = response;

                document.title = config?.appTitle ?? "myClaim";

                if (config?.adblock)
                    checkIfAdBlockEnabled(config.adblock)

                localforage
                    .getItem(taskId)
                    .then((result) => {
                        let saveOrUpdateData = {};

                        if (!result) {
                            /* Store new pyload */
                            saveOrUpdateData = { config, model, wedat };
                        } else {

                            /* Restore payload from disc and update configuration */
                            saveOrUpdateData = {
                                config,
                                model: result.model,
                                status: result?.status,
                                wedat: result?.status !== undefined ? result.wedat : wedat
                            };

                        }

                        /* Store config and data in localStorage (IndexedDB) */
                        return localforage.setItem(taskId, saveOrUpdateData);
                    })
                    .then((res) => {

                        /* Prepare routing */
                        routes.views = res.wedat.views;

                        /* Then Start! */
                        routes.go();
                    });
            } else {

                errorPage(i18n(response.status));

            }
        });

} else {
    errorPage(i18n("invalid-url"));
}
